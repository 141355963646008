<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.star">
			<template #title>Podcast Reviews</template>

			<v-row>
				<v-col cols="12" v-for="review in reviews" class="mb-3">
					{{ review.title }}
					<v-rating
						:length="review.rating"
						color="yellow darken-3"
						empty-icon="$ratingFull"
						background-color="yellow darken-3"
						readonly
						hover
					></v-rating>
				</v-col>
			</v-row>
		</ti-card>

	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "PodcastReviewsIndex",
	data: () => ({
		reviews: [],
		search: '',
		headers:[
			{
				text: 'From',
				align: 'start',
				sortable: true,
				value: 'redirect_from',
			},
			{
				text: 'To',
				align: 'start',
				sortable: true,
				value: 'redirect_to',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.getReviews()
		},
		editPage(id){
			this.$router.push({name:'RedirectsEdit', params: {id}})
		},
		getReviews(){
			this.$api.podcast_reviews.index()
				.then(response => {
					this.reviews = response.data
				})
				.catch(error => {
					console.log(error);
					this.$toast.add(error.message, 'error')
				})
		},
		filterOnlyCapsText (value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>